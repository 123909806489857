.PrivatePickersYear-root {
  color: #212634 !important;
}

.PrivatePickersYear-yearButton:disabled {
  color: grey !important;
  opacity: 0.25;
}

::-webkit-scrollbar {
  position: absolute;
  width: 0.5rem;
  height: 0rem;
  border-radius: 10px;
}

#find-a-specialist *::-webkit-scrollbar {
  position: absolute;
  width: 0.5rem;
  height: 0rem;
  border-radius: 10px;
  background-color: #ffffff !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ced0d7;
  border-radius: 10px;
}

.MuiCalendarPicker-root {
  color: grey !important;
}

.PrivatePickersFadeTransitionGroup-root {
  color: #212634 !important;
}

.MuiPickersDay-root,
.MuiTypography-caption {
  color: #212634 !important;
}

.forwardReferralSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 1px solid #e2e2e2;
  height: 50;
}

.MuiAvatar-colorDefault {
  background-color: #323232 !important;
}

.forwardReferralSearchInput {
  flex-grow: 1;
  margin-left: 10px;
  height: 45px;

  /* width: 23.5vw; */
  border: 0;
  padding: 10px;
  background: white;
  line-height: 21px;
  font-size: 14px;
  border-radius: 4px;
  outline: 0;
  border-right: 1px solid rgba(233, 232, 235, 1);
  -webkit-appearance: none;
}

.referral-table-header {
  width: 100% !important;
  background: #f7f7f7;
  border-radius: 4px;
  color: #212634;
  display: flex;
  padding: 1.5%;
}

.referral-table-header th {
  flex-grow: 1;
  text-align: start;
  font-weight: 400;
}

.referral-table-row {
  padding: 1%;
  display: flex;
  color: #212634;
  align-items: center;
}

.referral-table-item {
  flex-grow: 1;
  text-align: start;
  font-weight: 400;
}

.submenu-item:hover {
  background-color: rgba(71, 187, 146, 0.12) !important;
}

.referral-table-reason {
  display: block !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.3em;
  line-height: 1.8em;
}

.mui-table-row:hover {
  background-color: rgba(71, 187, 146, 0.12) !important;
}

.mui-table-row {
  border: 4px solid white;
}

.MuiPaginationItem-outlined {
  color: black !important;
}

.map-container-profile {
  height: 35vh;
  width: 75%;
}

.map-container-patient {
  height: 10em;
  width: 60%;
}

.inputs {
  display: grid;
  gap: 15px;
  margin-top: 5px;
  grid-template-columns: repeat(6, 1fr);
}

.inputs > * {
  width: 100%;
  padding: 20px;
  text-align: center;
  font-size: 32px;
  color: #47bb92;
  height: 100px;
  line-height: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-verification:focus {
  border: 3px solid #47bb92 !important;
  outline: none !important;
}

.image-item {
  padding: 20px 0 20px 20px;
}

/* .MuiTypography-gutterBottom {
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .inputs > *:active {
  border: 4px solid #47bb92;
} */
