body {
  padding: 0;
  margin: 0;
}

.map-container {
  height: 78vh;
  width: 100%;
}

.circle {
  background-color: #d83b01;
  border-radius: 50%;
  color: #fff;
  height: 2.5em;
  position: relative;
  width: 2.5em;

  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.hover {
  background-color: #0078d4;
}

#map-card {
  animation: fadeIn 350ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
