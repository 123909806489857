input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 300px;
  height: 2px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 0px;
  width: 0px;
  border-radius: 0px;
  background: #000;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.carousel-container-with-scrollbar {
  /* overflow: visible !important; */
  flex-direction: row;
  width: 100%;
  max-height: 50vh;
  overflow: hidden;
  /* justify-content: center; */
}

.carousel-container-with-scrollbar ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}
